#who {
  width: 70%;
  display: block;
  /* color: #fff; */
  align-content: center;
  justify-content: center;
  margin: auto;
  padding: 3rem;
  text-align: center;
  /* background-color: #354a57; */
  /* font-family: Chalkduster, fantasy; */
  font-family: 'Baloo 2', cursive;
  /* background-color: rgba(214, 215, 196, 0.9); */
}

#who .MuiPaper-root {
  width: 70vw;
  color: black;
  display: inline-block;
  background-color: inherit;
  /* color: rgba(255, 255, 255, 0.927); */
}

#who h1 {
  margin-block-start: 0px;
}

#who p {
  margin: 0 10%;
  text-align: left;
}
