/* .showcase-text {
  padding: auto;
  max-width: 80%;
  font-size: 0.7rem;
}

.showcase-text p {
  font-size: 1rem;
}
.showcase-text-bottom {
  max-width: 80%;
  padding: 10rem 0 0 10rem;
}

.showcase-text-bottom p {
  font-size: 1rem;
} */

.showcase-text {
  width: 800vw;
  height: 50vh;
  padding: 17% 0 0 0;
  max-width: 80%;
}

.showcase-text p {
  font-size: 1.8rem;
}
.showcase-text h1 {
  font-size: 2.2rem;
}
.showcase-text-bottom p {
  font-size: 1.6rem;
}

.showcase-text-bottom {
  width: 50vw;
  height: 50vh;
  padding: 22% 0 0 40%;
  margin: 0 0 0 0;
}

@media (min-width: 750px) {
  .showcase-text {
    width: 80vw;
    height: 50vh;
    padding: 25% 0 0 0;
    max-width: 80%;
  }
  /* .showcase-text p {
    font-size: 1.8rem;
  }
  .showcase-text h1 {
    font-size: 1.6rem;
  }
  .showcase-text-bottom p {
    font-size: 1.2rem;
  } */

  .showcase-text-bottom {
    width: 50vw;
    height: 50vh;
    /* padding: 6% 0 0 4%; */
    margin: 0 0 0 0;
  }
}

/* @media (min-width: 900px) {
  .showcase-text {
    width: 50vw;
    height: 50vh;
    padding: 25% 0 0 0;
    max-width: 80%;
  }

  .showcase-text p {
    font-size: 1.8rem;
  }
  .showcase-text h1 {
    font-size: 2.2rem;
  }
  .showcase-text-bottom p {
    font-size: 1.8rem;
  }

  .showcase-text-bottom {
    width: 50vw;
    height: 50vh;
    padding: 65% 0 0 45%;
    margin: 0 0 0 0;
  }
} */

@media (min-width: 1030px) {
  .showcase-text {
    width: 80vw;
    height: 50vh;
    padding: 15% 0 0 0;
    max-width: 80%;
  }

  .showcase-text p {
    font-size: 1.8rem;
  }
  .showcase-text h1 {
    font-size: 2.2rem;
  }
  .showcase-text-bottom p {
    font-size: 1.8rem;
  }

  .showcase-text-bottom {
    width: 50vw;
    height: 50vh;
    padding: 15% 0 0 40%;
    margin: 0 0 0 0;
  }
}
