.MuiCard-root {
  max-width: 40vw;
}

.flippy-container {
  padding: 2rem;
}

.flipCard-container {
  display: flex;
  text-align: center;
}

/* .thumb {
  max-width: inherit;
  max-height: inherit;
  object-fit: contain;
} */

.image-contain img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-contain {
  max-width: inherit;
}

.card-back {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.flipcard-buttons {
  width: 100%;
}

.inner-card-back {
  padding: 2rem;
}

.card-three {
  /* max-width: 50%; */
  margin: auto;
}

@media (min-width: 800px) {
  .card-three {
    max-width: 100%;
  }
}
