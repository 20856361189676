#why hr {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px;
}

#why {
  width: 70%;
  display: block;
  /* color: #fff; */
  align-content: center;
  justify-content: center;
  margin: auto;
  padding: 3rem;
  text-align: center;
  font-family: 'Baloo 2', cursive;
}

#why .MuiPaper-root {
  width: 70vw;
  color: black;
  display: inline-block;
  /* color: rgba(255, 255, 255, 0.927); */
  /* background-color: rgba(255, 255, 255, 0.927); */
}

#why h1 {
  margin-block-start: 0px;
}

#why p {
  margin: 0 10%;
  text-align: left;
}
