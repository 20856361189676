.App {
  text-align: center;
  background-image: linear-gradient(
      179deg,
      rgba(116, 116, 116, 0.02) 0%,
      rgba(116, 116, 116, 0.02) 25%,
      transparent 25%,
      transparent 96%,
      rgba(177, 177, 177, 0.02) 96%,
      rgba(177, 177, 177, 0.02) 100%
    ),
    linear-gradient(
      399deg,
      rgba(148, 148, 148, 0.02) 0%,
      rgba(148, 148, 148, 0.02) 53%,
      transparent 53%,
      transparent 59%,
      rgba(56, 56, 56, 0.02) 59%,
      rgba(56, 56, 56, 0.02) 100%
    ),
    linear-gradient(
      447deg,
      rgba(16, 16, 16, 0.02) 0%,
      rgba(16, 16, 16, 0.02) 46%,
      transparent 46%,
      transparent 71%,
      rgba(181, 181, 181, 0.02) 71%,
      rgba(181, 181, 181, 0.02) 100%
    ),
    linear-gradient(
      479deg,
      rgba(197, 197, 197, 0.02) 0%,
      rgba(197, 197, 197, 0.02) 26%,
      transparent 26%,
      transparent 49%,
      rgba(31, 30, 30, 0.02) 49%,
      rgba(31, 30, 30, 0.02) 100%
    ),
    linear-gradient(253deg, rgb(235, 241, 232), rgb(235, 241, 232));
}

.ContainedImage {
  height: 100%;
  width: 100%;

  /* background-image: url(./assets/showcase1.jpg);
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: 100% auto;
  background-repeat: no-repeat; */
}

#showcase {
  max-width: 100vw;
  height: 103vh;
  width: 100%;
  position: relative;
  /* color: var(--primary-dark-color);
  background: url(./assets/design_background2.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

#showcase .showcase-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* padding: 0 2rem; */
  /* Overlay */
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.4); */
  font-family: Chalkduster, fantasy;
  font-family: 'Baloo 2', cursive;
}

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.Headline {
  height: 100%;
  width: 100%;
}

@media (min-width: 600px) {
  #showcase {
    /* background: #333 url(./assets/design_background_tablet.png) no-repeat center
      center/cover; */
    height: 100vh;
  }
}

@media (min-width: 1035px) {
  #showcase {
    height: 100vh;
  }
}
