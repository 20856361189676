a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  display: inherit;
  text-align: inherit;
  position: inherit;
  text-align: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.MuiDrawer-paper {
  background-color: #354a57;
  color: #fff;
  width: 25vw;
}

.MuiListItemIcon-root {
  color: #fff;
}

.MuiListItem-root {
  padding: 1.25rem;
}

html {
  scroll-behavior: smooth;
}
